const computeTotal = (state) => {
    return state.products.reduce((total, arg) => total + (arg.price||0) * (arg.qty||0), 0);
}
const computeTax = (total, shipping, tax_rate, tax_shipping) => {
    return ((total + (tax_shipping?shipping:0)) * tax_rate).toFixed(2)
}
const computeShipping = (total, onlyBands) => {
    const min = onlyBands ? 20 : 40;
    const max = 750;
    const percentage = 0.015
    return Math.min(Math.max(min, (total) * percentage), max)
}
const BAND_TYPE = 'Watch Bands';

export {computeShipping, computeTotal, computeTax, BAND_TYPE}