/**
 * Implement Gatsby's Browser APIs in this file.
 */
import wrapWithProvider from "./redux-provider"

const initKlaviyoForms = () => {
  const script = document.createElement("script")
  script.src = "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=UEz6BT"
  script.async = true
  script.type = "text/javascript"
  document.body.appendChild(script)
}

const initGTM = () => {
  // GTM head script
  (function(w, d, s, l, i) {
    w[l] = w[l] || []
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : ""
    j.async = true
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
    f.parentNode.insertBefore(j, f)
  })(window, document, "script", "dataLayer", "GTM-W4V7LW")
}

const initCollectChat = () => {
  window.CollectId = "5defba29fb5ebe491c20e4b7"
  window.collectchat = window.collectchat || {}
  const script = document.createElement("script")
  script.type = "text/javascript"
  script.async = true
  script.src = "https://collectcdn.com/launcher.js"
  document.head.appendChild(script)

  const f = window.collectchat.ready
  window.collectchat.ready = function() {
    if (f) {
      f()
    }
    window.collectchat.on("open", function() {
      if (window.ga) {
        window.ga("send", {
          hitType: "event",
          eventCategory: "Chatbox",
          eventAction: "Open",
          eventLabel: "Open",
        })
      }
    })
    window.collectchat.on("close", function() {
      if (window.ga) {
        window.ga("send", {
          hitType: "event",
          eventCategory: "Chatbox",
          eventAction: "Close",
          eventLabel: "Close",
        })
      }
    })
    window.collectchat.on("complete", function() {
      if (window.ga) {
        window.ga("send", {
          hitType: "event",
          eventCategory: "Chatbox",
          eventAction: "complete",
          eventLabel: "complete",
        })
        window.ga("send", "event", "Submission", "Chat", "Collect.Chat")
      }
    })
    window.collectchat.on("message", function(details) {
      if (window.ga) {
        window.ga("send", {
          hitType: "event",
          eventCategory: "Chatbox",
          eventAction: "Answered question",
          eventLabel: details.question,
        })
      }
    })
  }
}

const initRudderStack = () => {
  window.rudderanalytics.loadJS()
}

const initScripts = () => {
  if (window.scriptsDidInit) {
    return false
  }
  window.scriptsDidInit = true // flag to ensure script does not get added to DOM more than once.
  initRudderStack()
  initCollectChat()
  initKlaviyoForms()
  initGTM()
}

const initScriptsOnEvent = event => {
  initScripts()
  event.currentTarget.removeEventListener(event.type, initScriptsOnEvent) // remove the event listener that got triggered
}

export const onClientEntry = () => {
  let initial_page = window.sessionStorage.getItem("initial_page")
  if (!initial_page) {
    window._initial_page = window.location.href
    window.sessionStorage.setItem("initial_page", window._initial_page)
  } else {
    window._initial_page = initial_page
  }
  !(function() {
    var e = (window.rudderanalytics = window.rudderanalytics || [])
      ; (e.methods = [ "load", "page", "track", "identify", "alias", "group", "ready", "reset", "getAnonymousId", "setAnonymousId", ]),
    (e.factory = function(t) {
      return function() {
        var r = Array.prototype.slice.call(arguments)
        return r.unshift(t), e.push(r), e
      }
    })
    for (var t = 0; t < e.methods.length; t++) {
      var r = e.methods[t]
      e[r] = e.factory(r)
    }
    (e.loadJS = function() {
      var r = document.createElement("script")
      r.type = "text/javascript"
      r.async = !0
      r.src = "https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js"
      var a = document.getElementsByTagName("script")[0]
      a.parentNode.insertBefore(r, a)
    }),
    e.load("27faVpCjbq685Ib6DXonnfPjRD3", "https://dibbygrzeolg.dataplane.rudderstack.com")
  })()
  document.addEventListener("DOMContentLoaded", () => {
    setTimeout(initScripts, 100)
  })
}

const klMakeRequest = (kl_method, kl_payload) => {
  const data = encodeURI(btoa(JSON.stringify(kl_payload)))
  const xhr = new XMLHttpRequest()
  const url = `https://a.klaviyo.com/api/${kl_method}?data=${data}`
  return new Promise(function(resolve, reject) {
    // log response when request completes
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4 && xhr.status === 200) {
        resolve()
      } else {
        reject()
      }
    }
    // open the request
    xhr.open("GET", url, true)
    // send request
    xhr.send()
  })
}
const klHistoricalBackFilling = () => {
  if (!window.Worker) return
  window._learnq = window._learnq || []
  if (window._learnq._loaded !== true) {
    setTimeout(klHistoricalBackFilling, 500)
    return
  }
  const _learnq = window._learnq
  let klCookie = document.cookie.split("; ").filter(function(c) {
    return /__kla_id=/.test(c)
  })
  let klCookieData
  if (Array.isArray(klCookie) && klCookie.length) {
    try {
      klCookieData = JSON.parse(atob(klCookie[0].split("__kla_id=")[1]))
    } catch (_e) {
      klCookieData = {}
    }
  }

  // Create a new Web Worker
  const myWorker = new Worker("/worker.js")
  myWorker.onmessage = function(e) {
    // Worker has returned the data
    if (e.data.operation === "getLocalLearnqResult") {
      const localLearnQ = e.data.data
      const klEmail = _learnq.identify().$email
      while (localLearnQ.length > 0) {
        const { type, payload } = localLearnQ.pop()
        //klRequest[1] = JSON.parse(klRequest[1])
        if (type === "identify") {
          payload["properties"]["$email"] = klEmail
        }
        if (type === "track") {
          payload["customer_properties"] = { $email: klEmail }
        }
        klMakeRequest(type, payload).then(() => {
          // do nothing
        })
      }
    }
  }

  if (klCookieData && !!klCookieData["$email"]) {
    myWorker.postMessage({ operation: "getLocalLearnq" })
    return
  }
  _learnq.old_push = _learnq.push
  _learnq.push = function(request) {
    if (request[0] === "identify") {
      if (Object.keys(request[1]).includes("$email")) {
        _learnq.push = _learnq.old_push
        _learnq.push(["identify", { $email: request[1]["$email"] }])
        myWorker.postMessage({
          operation: "getLocalLearnq",
          data: {
            clear: true,
          },
        })
      } else {
        myWorker.postMessage({
          operation: "klLocalIdentify",
          data: request[1],
        })
      }
    } else if (request[0] === "track") {
      myWorker.postMessage({
        operation: "klLocalTrack",
        data: {
          eventName: request[1],
          eventPayload: request[2],
        },
      })
    }
  }
}

export const onInitialClientRender = () => {
  document.addEventListener("scroll", initScriptsOnEvent)
  document.addEventListener("mousemove", initScriptsOnEvent)
  document.addEventListener("touchstart", initScriptsOnEvent)
  klHistoricalBackFilling()
}

const getQueryString = () => {
  const item = window.sessionStorage.getItem("qs")
  if (!item) return {}
  try {
    const qs = JSON.parse(item)
    window.qs = qs
    return qs
  } catch {
    return {}
  }
}

const setQueryString = qs => {
  window.sessionStorage.setItem("qs", JSON.stringify(qs))
  window.qs = qs
}

// const unpushedPageVisits = []
// let pushPageVisitInterval = null
// const recordPageVisitOnEvent = () => {
//   const page = window.location.pathname
//   const time = new Date().getTime()
//   recordPageVisit(page, time)
// }
// const recordPageVisit = (page, time) => {
//   if (window.ruderanalytics) {
//     window.ruderanalytics.page(page, time)
//     return
//   }
//   unpushedPageVisits.push({ page, time })
//   if (!pushPageVisitInterval) {
//     pushPageVisitInterval = setInterval(() => {
//       if (window && window.ruderanalytics && window.ruderanalytics.page) {
//         clearInterval(pushPageVisitInterval)
//         pushPageVisitInterval = null
//         while (unpushedPageVisits.length > 0) {
//           const pageVisit = unpushedPageVisits.shift()
//           window.ruderanalytics.page(pageVisit.page, pageVisit.time)
//         }
//       }
//     }, 1000)
//   }
// }

export const onRouteUpdate = ({ location }) => {
  const vars = getQueryString()
  if (location.search) {
    const query = location.search.substring(1)
    query.split("&").forEach(v => {
      const pair = v.split("=")
      if (pair.length > 1) {
        vars[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
      } else {
        vars[decodeURIComponent(pair[0])] = null
      }
    })
    setQueryString(vars)
  }
  window?.rudderanalytics?.page()
  // recordPageVisitOnEvent()
}
export const wrapRootElement = wrapWithProvider
