import { createSlice } from "@reduxjs/toolkit"
import {
  computeShipping,
  computeTax,
  computeTotal,
  BAND_TYPE,
} from "../Common/checkout"

const initCart = {
  products: [],
  shipping: 0,
  tax: 0,
  total: 0,
  tax_rate: 0,
  tax_shipping: false,
}

export const cartSlice = createSlice({
  name: "cart",
  initialState: initCart,
  reducers: {
    updateTax: (state, action) => {
      const { tax, tax_rate, tax_shipping } = action.payload
      state.tax = tax
      state.tax_rate = tax_rate
      state.tax_shipping = tax_shipping
    },
    addProduct: (state, action) => {
      const {
        sku,
        price,
        name,
        title,
        image,
        url,
        brand,
        model,
        color,
        size,
        qty,
        availQty,
        itemType,
      } = action.payload
      const index = state.products.findIndex(product => product.sku === sku)
      if (index === -1) {
        state.products.push({
          sku: sku,
          price: price,
          name: name,
          title: title,
          image: image,
          url: url,
          brand: brand,
          model: model,
          color: color,
          size: size,
          itemType: itemType,
          qty: Math.min(availQty || qty || 1, qty || 1),
        })
      } else {
        const product = state.products[index]
        state.products[index].qty = availQty
          ? Math.min(availQty, product.qty + (qty || 1))
          : product.qty + (qty || 1)
      }
      const onlyBands =
        state.products.find(p => p.itemType !== BAND_TYPE).length === 0
      console.log("compute total")
      state.total = computeTotal(state)
      console.log("shipping total", state.total, onlyBands)
      state.shipping = computeShipping(state.total, onlyBands)
      console.log("compute tax", state.total, state.tax)
      // total, shipping, tax_rate, tax_shipping
      state.tax = computeTax(
        state.total,
        state.shipping,
        state.tax_rate,
        state.tax_shipping
      )
    },
    removeProduct: (state, action) => {
      const { sku, qty } = action.payload
      const index = state.products.findIndex(p => p.sku === sku)
      if (index === -1) {
        return
      }
      const product = state.products[index]
      if (qty && qty < product.qty) {
        state.products[index].qty -= qty
      } else {
        state.products.splice(index, 1)
      }
      state.total = computeTotal(state)
      state.shipping = computeShipping(state.total)
      state.tax = computeTax(
        state.total,
        state.tax,
        state.tax_rate,
        state.tax_shipping
      )
    },
  },
})

// Action creators are generated for each case reducer function
export const { addProduct, removeProduct, updateTax } = cartSlice.actions
export { initCart }

export default cartSlice.reducer
