import { configureStore } from '@reduxjs/toolkit'
import searchSlice from './search'
import cartSlice from './cart'
import checkoutSlice from './checkout'
import {loadState} from "./localStorage";

const createStore = () => configureStore({
    preloadedState: loadState(),
    reducer: {
        cart: cartSlice,
        search: searchSlice,
        checkout: checkoutSlice,
        // [taxApi.reducerPath]: taxApi.reducer
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(taxApi.middleware),
})


export default createStore
